<template>
  <router-view></router-view>
  <!-- <div>
    <v-container fluid>
      <v-card>
        <ProjectIterator
          :projects="projects"
          :checked="checked"
          :readonly="false"
          :iconColor="iconColor"
          @deleteProject="deleteProject"
        />
      </v-card>
    </v-container>
  </div> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import ProjectIterator from '@/components/projects/ProjectIterator';

export default {
  name: 'Projects',
  // components: { ProjectIterator },
  data() {
    return {
      checked: [],
    };
  },
  methods: {
    deleteProject(publicId) {
      this.$store
        .dispatch('projects/deleteProject', publicId)
        .then(response => {
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          console.log(error);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
  },
  computed: {
    ...mapGetters('projects', ['projects', 'emptyProject']),
    ...mapGetters(['iconColor']),
  },
  mounted() {},
};
</script>

<style></style>
